export default {
	bodyWidth: {
		maxWidth: 1250,
		width: "100%",
		display: "flex",
		flexDirection: "column",
		paddingTop: 32,
		paddingBottom: 58,
		flex: 1,
		height: '100%',
		boxSizing: 'border-box',
		overflow: 'hidden'
	},
	footerLegal: {
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	footerWrapper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: 44,
		background: "linear-gradient(180deg, rgb(243, 232, 241) 30%,  rgb(227, 197, 220) 150%)"
	},
	footerInternal: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
		margin: "0 20px"
	},
	footerText: {
		fontSize: 18,
	},
	modalContent: {
		width: 200,
		paddingTop: 10,
		display: 'flex',
		marginRight: 50,
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center'
	}
}
