import React, {useEffect, useState} from "react";

import {STRING} from "../../../constants/strings/strings";

import Body from "../../../components/layout/Body/Body";
import MainBackground from "../../../components/layout/MainBackground/MainBackground";
import AffirmationCard from "../../../components/units/AffirmationCard/AffirmationCard";

import styles from "./styles";
import {getAfirmacionesInSessionService} from "../../../data/service/delegadoService";
import {useNavigate, useParams} from "react-router-dom";
import Colors from "../../../theme/colors/colors";
import Button from "../../../components/units/Button/Button";
import {getConfig} from "../../../data/api/medico-api";
import {saveToSessionStorage} from "../../../constants/auth";

const Affirmations = () => {

	const {meetingType, roomCode} = useParams();

	const navigate = useNavigate();

	const [fetching, setFetching] = useState(true);

	const [afirmaciones, setAfirmaciones] = useState([]);

	const [urlSatisfaccion, setUrlSatisfaccion] = useState("");

	let polling = null;

	useEffect(() => {
		fetchData()
		if (!polling) polling = setInterval(fetchData, 4000);

		return function cleanup() {
			clearInterval(polling)
		}
	}, [])

	const handleClickAffirmation = (affirmationId) => {
		navigate(`/delegado/affirmations/${meetingType}/${roomCode}/${affirmationId}/video`);
	}

	const fetchData = () => {
		setFetching(true);
		getAfirmacionesInSessionService(roomCode)
			.then(r => {
				console.log(r);
				setAfirmaciones(r)
				setFetching(false);
			})
			.catch(err => {
				setFetching(false);
			})
		getConfig()
			.then(r => {
				const data = r?.data;
				if (data) {
					setUrlSatisfaccion(data.url_encuesta_satisfaccion)
				}
			})
			.catch(err => {
				console.log(err);
			})
	}

	return (
		<Body>
			<MainBackground roomCode={roomCode}
							loading={fetching && Object.keys(afirmaciones).length === 0}>
				<h2 style={{
					fontFamily: 'Poppins',
					textAlign: "center",
					fontSize: 32,
					letterSpacing: '0.15em',
					color: Colors.accent,
					margin: "24px 0"
				}}>{STRING.AFFIRMATIONS}</h2>
				<div style={{
					padding: 4,
					width: 64,
					background: Colors.accent,
					borderRadius: 4,
					margin: '0 auto'
				}}/>
				<div style={styles.mainContainer}>
					{Object.keys(afirmaciones).map((category, index) =>
						<>
							<h2 style={{
								marginTop: 24,
								marginBottom: 8,
								fontSize: 20
							}}>{afirmaciones[category]?.name}</h2>
							<div style={styles.container}>
								{afirmaciones[category]?.affirmations?.map((item, index) =>
									<AffirmationCard key={index} content={item}
													 onClick={() => handleClickAffirmation(item._id)}/>)}
							</div>
						</>
					)}
				</div>
				{urlSatisfaccion && <div style={{maxWidth: 340, margin: '0 auto', paddingBottom: 30}}>
					<Button title={"Encuesta de satisfacción   ➝"} onClick={() => {
						window.open(`/quiz/opinions`)
					}}/>
				</div>}
			</MainBackground>
		</Body>
	)
};

export default Affirmations;
