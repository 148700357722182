import Colors from "../../../theme/colors/colors";

export default {
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingTop: 100,
		overflowY: 'auto'
	},
	logo: {
		height: 240
	},
	title: {
		fontFamily: 'Poppins',
		fontSize: 28,
		color: Colors.white,
		marginTop: 56,
		marginBottom: 12
	},
	modalContent: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingBottom: 12,
		paddingRight: 50
	},
	closeModalIconContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%'
	},
	closeModalIcon: {
		fontSize: 32,
		transform: 'rotate(45deg)',
		cursor: 'pointer'
	},
	errorTitle: {
		fontFamily: 'Poppins',
		fontSize: 40,
		marginBottom: 20
	},
	errorMsg: {
		fontSize: 18,
		marginBottom: 30,
		fontWeight: 400
	}
};
