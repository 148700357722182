import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import LOGO from "../../../assets/landing/logo-landing.png";
import {STRING} from "../../../constants/strings/strings";

import Body from "../../../components/layout/Body/Body";
import Button from "../../../components/units/Button/Button";
import Input from "../../../components/units/Input/Input";

import styles from "./styles";
import {getUserInfo} from "../../../constants/auth";
import {createNewRoomService} from "../../../data/service/delegadoService";
import {saveSessionInfo} from "../../../constants/room";

const RoomCreator = () => {
	const [roomCode, setRoomCode] = useState("");
	const navigate = useNavigate();
	const {meetingType} = useParams();
	const location = useLocation();

	const isVisit = meetingType === "visit";

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (isVisit) handleOnCreateRoomClick();
	}, [])

	const handleOnCreateRoomClick = () => {
		setLoading(true);

		let delegadoId = getUserInfo()?._id;

		let roomName = roomCode;
		if (isVisit) roomName = "Visita"

		createNewRoomService(roomName, delegadoId)
			.then(r => {
				saveSessionInfo(r);
				const sessionId = r?._id;
				if (!sessionId) {
					alert("Error creando la sala");
					return;
				}
				if (isVisit) {
					setTimeout(() => {
						setLoading(false);
						navigate(`/delegado/register/${meetingType}`)
					}, 2000);
				} else {
					setLoading(false);
					navigate(`/delegado/waiting-room/${meetingType}/${sessionId}`);
				}
			})
			.catch(err => {
				setLoading(false);
				alert("Error creando la sala");
			})

	};

	return (
		<Body containerStyle={styles.container}>
			<div style={styles.topContainer}>
				<img
					style={styles.logo}
					src={LOGO}
					alt={"Logo"}
				/>
				<p
					style={styles.title}>
					{STRING.CREATE_ROOM_TITLE}
				</p>
				{isVisit &&
					<p
						style={styles.title}>
						Te redirigiremos automáticamente
					</p>
				}
				{!isVisit && <Input
					placeholder={STRING.ROOM_NAME}
					onChange={e => setRoomCode(e.target.value)}
					value={roomCode}
					customStyle={styles.input}
				/>}
			</div>
			<div style={{marginTop: 40}}>
				<Button
					disable={!isVisit && !roomCode}
					title={STRING.CREATE_ROOM}
					onClick={handleOnCreateRoomClick}
					loading={loading}
				/>
			</div>
		</Body>
	)
};

export default RoomCreator;
