import React from 'react';

import Colors from "../../../theme/colors/colors";
import styles from "./styles";

import Loader from "../loader/Loader";

const Button = ({
					title, onClick = () => {
	}, disable = false, loading = false
				}) => {
	return (
		<button type={"submit"} style={{
			...styles.container, ...disable ? {
				backgroundColor: Colors.disabledAccent,
				cursor: 'auto'
			} : {}
		}} onClick={() => !disable && onClick()}>
			{loading
				? <Loader/>
				: <p style={styles.title}>{title}</p>
			}
		</button>
	)
};

export default Button;
