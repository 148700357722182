import React, {useEffect, useState} from "react";
import LegalBases from "./LegalBases";
import QRCode from "react-qr-code";
import {getConfig} from "../../data/api/medico-api";
import Loader from "../../components/units/loader/Loader";

const QRSatisfaction = () => {

	const [qrUrl, setQrUrl] = useState("");

	useEffect(() => {
		getConfig()
			.then(r => {
				const data = r?.data;
				if (data) {
					setQrUrl(data.url_encuesta_satisfaccion)
				}
			})
			.catch(err => {
				console.log(err);
			})
	}, [])


	return (
		<LegalBases>
			<div style={{textAlign: "center"}}>
				<h1>Encuesta de satisfacción</h1>
				{!qrUrl
					?  <Loader color={'accent'}/>
					: <>
						<p>Escanea este código QR para valorar la reunión de THIS or THAT</p>
						<br/>

						<QRCode
							value={qrUrl}
							level={"H"}
							size={306}
						/>
						<br/>
						<br/>
						<p>{qrUrl}</p>
					</>
				}
			</div>
		</LegalBases>
	)
}

export default QRSatisfaction;
