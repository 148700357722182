import Colors from "../../../theme/colors/colors";

export default {
	container: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		paddingTop: 100,
		justifyContent: 'flex-start',
		alignItems: 'center',
		overflowY: 'auto'
	},
	topContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	logo: {
		height: 240
	},
	title: {
		fontFamily: "Poppins",
		fontSize: 28,
		color: Colors.white,
		marginTop: 56,
		marginBottom: 14
	},
	input: {
		marginBottom: 16
	}
};
