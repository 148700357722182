export default {
	white: "#FFFFFF",
	primary: "#46217E",
	accent: "#A93180",
	completed: "#E4FDE1",
	text: "#46217E",
	davyGray: "#575757",
	disabledAccent: "rgb(188,158,178)",
	silverSand: "#C4C4C4",
	green: 'linear-gradient(180deg, rgba(182, 241, 191, 0.2) 0%, rgba(181, 248, 231, 0.2) 100%), #FFFFFF',
	greenBorder: '#1ACA9F',
	greenText: '#498360',
	red: '#ae2f2f',
	affirmationsBorder: '#EFCEE4'
}
