import React, {useEffect, useState} from "react";
import Body from "../../../components/layout/Body/Body";
import MainBackground from "../../../components/layout/MainBackground/MainBackground";
import {STRING} from "../../../constants/strings/strings";
import {getSessionInfo, saveSessionInfo} from "../../../constants/room";
import {getUserInfo} from "../../../constants/auth";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Colors from "../../../theme/colors/colors";
import VerdadOMitoCard from "../../../components/units/VerdadOMitoCard/VerdadOMitoCard";
import {getSessionStatusService} from "../../../data/service/medicoService";
import eye from "../../../assets/lottie/87081-blood-cell-morph.json";
import Lottie from "lottie-react";

const AnswersScreen = () => {

	const location = useLocation();
	const selectedCard = location?.state?.selectedCard;
	const afirmacion = location?.state?.afirmacion;

	console.log(selectedCard, afirmacion);

	const [fetching, setFetching] = useState(false);

	const [showAnswer, setShowAnswer] = useState(false);

	const {meetingType, roomCode, affirmationId} = useParams();

	const navigate = useNavigate();

	const session = getSessionInfo();

	const medico = getUserInfo();

	let polling = null;

	useEffect(() => {
		fetchSession()
		if (!polling) polling = setInterval(fetchSession, 2000);

		return function cleanup() {
			clearInterval(polling)
		}
	}, [])

	const fetchSession = () => {
		setFetching(true);
		getSessionStatusService(roomCode)
			.then(r => {
				saveSessionInfo(r[0]);
				setFetching(false);
				const activeAffirmationId = r[0]?.afirmacion_activa?._id;
				if (!activeAffirmationId) {
					setShowAnswer(true);
					return;
				}
				if (!!activeAffirmationId && (activeAffirmationId !== affirmationId)) {
					navigate(`/medico/meeting/${roomCode}/affirmationAnswer/${activeAffirmationId}`)
				}
			})
			.catch(err => {
				setFetching(false);
				alert(err);
			})

	}

	return (
		<Body showMeetingText={false} mobile={true}>
			<MainBackground mobile={true} showLogo={false}>

				<div style={{textAlign: "center", width: '100%'}}>
					<p style={{
						fontWeight: 600,
						fontSize: 16,
						marginTop: 0,
						marginBottom: 4
					}}>{session?.nombre}</p>
					<p style={{fontSize: 18}}>{medico?.nombre} - <span>{medico?.hospital}</span></p>
					<div style={{
						backgroundColor: 'white',
						border: '1px solid var(--primary)',
						padding: 10,
						borderRadius: 10,
						margin: '12px 0',
						textAlign: "center"
					}}>
						<p style={{
							fontWeight: 800,
							color: Colors.text,
							fontSize: 20
						}}>{afirmacion?.pregunta}</p>
					</div>
				</div>
				{showAnswer
					? <div style={{
						flex: 1,
						display: "flex",
						gap: 12,
						flexDirection: "column",
						alignItems: "center",
						paddingBottom: 60
					}}>
						<VerdadOMitoCard verdad={selectedCard === STRING.VERDAD_CARD_TEXT}
										 text={selectedCard}
										 shouldSelect={false} addBorder={true}/>
						{selectedCard === afirmacion?.respuesta
							? <p style={{
								fontWeight: 800,
								color: Colors.greenText,
								fontSize: 20,
								textAlign: "center"
							}}>¡Respuesta correcta!</p>
							: <p style={{
								fontWeight: 800,
								color: Colors.red,
								fontSize: 20,
								textAlign: "center"
							}}>¡Respuesta incorrecta!</p>
						}
						<p style={{
							fontWeight: 800,
							color: Colors.text,
							fontSize: 20,
							textAlign: "center"
						}}>Espere a que se active otra pregunta.</p>
					</div>
					: <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
						<Lottie animationData={eye} style={{width: 150}} loop={true}/>
						<p style={{
							fontWeight: 800,
							color: Colors.text,
							fontSize: 20,
							textAlign: "center"
						}}>Esperando las respuestas de los demás...</p>
					</div>}

			</MainBackground>
		</Body>
	)
}

export default AnswersScreen;
