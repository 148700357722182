import Colors from "../../../theme/colors/colors";

export default {
	cardContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: 16,
		gap: 16,
		color: Colors.primary
	},
	cardImage: {
		width: 90
	}
}
