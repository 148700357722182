import Colors from "../../../theme/colors/colors";

export default {
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 14
	},
	cardContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: 16,
		gap: 16,
		color: Colors.primary
	},
	cardImage: {
		width: 90
	}
};
