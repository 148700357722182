import React, {useEffect, useState} from "react";
import Body from "../../components/layout/Body/Body";
import MainBackground from "../../components/layout/MainBackground/MainBackground";
import {useParams} from "react-router-dom";
import {getLegalTexts} from "../../data/api/medico-api";
import {saveToSessionStorage} from "../../constants/auth";
import Loader from "../../components/units/loader/Loader";

const LegalBases = ({children }) => {

	const [pageText, setPageText] = useState("");

	const {legalType} = useParams();

	const setText = (data) => {
		switch (legalType) {
			case "legal-bases":
				setPageText(data.bases_legales);
				break;
			case "legal-warning":
				setPageText(data.aviso_legal);
				break;
			case "cookies-policy":
				setPageText(data.cookies);
				break;
			case "privacy-policy":
				setPageText(data.privacidad);
				break;

		}
	}

	useEffect(() => {
		if (children) {
			setPageText(" ")
			return;
		}
		getLegalTexts()
			.then(r => {
				const data = r?.data;
				if (data) {
					const saveData = {
						legalTexts: data
					}
					saveToSessionStorage(JSON.stringify(saveData));
				}
				setText(data);

			})
			.catch(err => {
				setPageText("Error obteniendo los textos legales");
				console.log(err);
			})
	}, [])

	return (
		<Body>
			<MainBackground>
				{!pageText && <div style={{textAlign: "center"}}> <Loader color={'accent'}/></div>}
				<div dangerouslySetInnerHTML={{__html: pageText}}/>
				{children}
				<p>&nbsp;</p>
				<p>&nbsp;</p>
				<p>&nbsp;</p>
			</MainBackground>
		</Body>
	)
}

export default LegalBases;
