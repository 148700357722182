import React, {useEffect} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import MainBackground from "../../../components/layout/MainBackground/MainBackground";
import Body from "../../../components/layout/Body/Body";
import VerdadOMitoCard from "../../../components/units/VerdadOMitoCard/VerdadOMitoCard";
import Button from "../../../components/units/Button/Button";


import Colors from "../../../theme/colors/colors";
import {saveSessionInfo} from "../../../constants/room";
import {getDoctorInfo} from "../../../constants/auth";
import {STRING} from "../../../constants/strings/strings";

import {getSessionStatusService} from "../../../data/service/medicoService";

const AffirmationRevealVisit = () => {

	const location = useLocation();
	const selectedCard = location?.state?.selectedCard;
	const afirmacion = location?.state?.afirmacion;

	const {meetingType, roomCode, affirmationId} = useParams();

	const navigate = useNavigate();

	const medico = getDoctorInfo();

	useEffect(() => {
		fetchSession()

	}, [])

	const fetchSession = () => {
		getSessionStatusService(roomCode)
			.then(r => {
				saveSessionInfo(r[0]);
			})
			.catch(err => {
				alert(err);
			})
	};

	const handleOnGoToAffirmations = () => {
		navigate(`/delegado/affirmations/${meetingType}/${roomCode}/${affirmationId}/justification/visita`)
		// navigate(`/delegado/affirmations/${meetingType}/${roomCode}`)
	};

	return (
		<Body>
			<MainBackground enableBackButton={false}>
				<div style={{textAlign: "center", width: '100%', marginBottom: 32}}>
					<div style={{
						backgroundColor: 'white',
						border: '1px solid var(--primary)',
						padding: 10,
						borderRadius: 10,
						margin: '12px 0',
						textAlign: "center",
					}}>
						<p style={{
							fontWeight: 800,
							color: Colors.text,
							fontSize: 18
						}}>{afirmacion?.pregunta}</p>
					</div>
				</div>
				<div style={{
					flex: 1,
					display: "flex",
					gap: 12,
					flexDirection: "column",
					alignItems: "center",
					paddingBottom: 60
				}}>
					<VerdadOMitoCard verdad={selectedCard === STRING.VERDAD_CARD_TEXT}
									 text={selectedCard} shouldSelect={false} addBorder={true}/>
					{selectedCard === afirmacion?.respuesta
						? <p style={{
							fontWeight: 800,
							color: Colors.greenText,
							fontSize: 20,
							textAlign: "center",
						}}>¡Respuesta correcta!</p>
						: <p style={{
							fontWeight: 800,
							color: Colors.red,
							fontSize: 20,
							textAlign: "center",
						}}>¡Respuesta incorrecta!</p>
					}
				</div>
				<div style={{display: 'flex', justifyContent: 'center', paddingBottom: 50}}>
					<div>
						<Button title={STRING.CONTINUE} onClick={handleOnGoToAffirmations}/>
					</div>
				</div>
			</MainBackground>
		</Body>
	)
};

export default AffirmationRevealVisit;
