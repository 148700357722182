import React, {useEffect, useState} from "react";
import Body from "../../../components/layout/Body/Body";
import MainBackground from "../../../components/layout/MainBackground/MainBackground";
import Button from "../../../components/units/Button/Button";
import {STRING} from "../../../constants/strings/strings";
import {getSessionInfo} from "../../../constants/room";
import {getUserInfo} from "../../../constants/auth";
import {getAfirmacionService} from "../../../data/service/delegadoService";
import {useNavigate, useParams} from "react-router-dom";
import Colors from "../../../theme/colors/colors";
import VerdadOMitoCard from "../../../components/units/VerdadOMitoCard/VerdadOMitoCard";
import {postAnswerMedicoService} from "../../../data/service/medicoService";
import styles from "./styles";
import thisCard from "../../../assets/this-card.png";
import thatCard from "../../../assets/that-card.png";

const AnswersScreen = () => {

	const [fetching, setFetching] = useState(false);
	const [posting, setPosting] = useState(false);

	const [selectedCard, setSelectedCard] = useState(null);

	const {meetingType, roomCode, affirmationId} = useParams();

	const navigate = useNavigate();

	const [afirmacion, setAfirmacion] = useState([]);

	const session = getSessionInfo();

	const medico = getUserInfo();

	useEffect(() => {
		fetchAfirmacion()
	}, [])

	const fetchAfirmacion = () => {
		setFetching(true);
		getAfirmacionService(affirmationId, roomCode)
			.then(r => {
				console.log(r);
				setAfirmacion(r)
				setFetching(false);
			})
			.catch(err => {
				setFetching(false);
			})
	}

	const postAnswer = () => {
		if (posting) return;
		setPosting(true);
		postAnswerMedicoService(roomCode, affirmationId, medico?._id, selectedCard)
			.then(r => {
				console.log(r);
				setPosting(false);
				navigate(`/medico/meeting/${roomCode}/affirmationReveal/${affirmationId}`, {
					state: {
						selectedCard,
						afirmacion
					}
				})
			})
			.catch(err => {
				setPosting(false);
			})
	}

	const [showOptions, setShowOptions] = useState(false);

	const toggleShowOptions = () => {
		setShowOptions(!showOptions);
	}


	return (
		<Body showMeetingText={false} mobile={true}>
			<MainBackground loading={fetching} mobile={true} showLogo={false} enableBackButton={showOptions} title={"Responder pregunta"} backButtonPress={toggleShowOptions}>
				{showOptions
					? <>
						<div style={{
							backgroundColor: 'white',
							border: '1px solid var(--primary)',
							padding: 10,
							borderRadius: 10,
							margin: '12px 0',
							textAlign: "center"
						}}>
							<p style={{
								fontWeight: 800,
								color: Colors.text,
								fontSize: 18
							}}>{afirmacion?.pregunta}</p>
						</div>
						<div style={styles.cardContainer}>
							<img src={thisCard} style={styles.cardImage}/>
							{afirmacion?.texto_this}
						</div>
						<div style={styles.cardContainer}>
							<img src={thatCard} style={styles.cardImage}/>
							{afirmacion?.texto_that}
						</div>
					</>
					: <>
						<div style={{textAlign: "center", width: '100%'}}>
							<p style={{fontWeight: 600, fontSize: 16, margin: 0}}>{session?.nombre}</p>
							<p style={{fontSize: 18}}>{medico?.nombre} - <span>{medico?.hospital}</span></p>
							<div style={{maxWidth: 300, margin: '0px auto', marginBottom: 40, marginTop: 20}}>
								<Button title={"Recordar pregunta"} onClick={toggleShowOptions}/>
							</div>
						</div>
						<div style={{
							flex: 1,
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: 'center',
							flexWrap: 'wrap',
							paddingBottom: 60,
							gap: 0,
							alignContent: 'flex-start'
						}}>
							<VerdadOMitoCard verdad={true} text={STRING.VERDAD_CARD_TEXT}
											 shouldSelect={selectedCard !== null}
											 selected={selectedCard === STRING.VERDAD_CARD_TEXT}
											 onCardClick={() => setSelectedCard(STRING.VERDAD_CARD_TEXT)}/>
							<VerdadOMitoCard verdad={false} text={STRING.MITO_CARD_TEXT}
											 shouldSelect={selectedCard !== null}
											 selected={selectedCard === STRING.MITO_CARD_TEXT}
											 onCardClick={() => setSelectedCard(STRING.MITO_CARD_TEXT)}/>

						</div>
						<div style={{
							position: "absolute",
							bottom: 8,
							left: 0,
							display: "flex",
							justifyContent: "center",
							width: '100%',
						}}>
							<div style={{maxWidth: 200}}>
								<Button title={STRING.CONTINUE} onClick={postAnswer}
										disable={selectedCard === null} loading={posting}/>
							</div>
						</div>
					</>

				}

			</MainBackground>
		</Body>
	)
}

export default AnswersScreen;
